import React from 'react';
import { graphql } from 'gatsby';
import { TermsRichText } from '../components/atoms';

interface TermsOfUsePageProps {
  data: {
    prismicTermsOfUsePage: {
      data: {
        body: {
          primary: {
            terms_of_use_country_title: {
              raw: any;
            };
            terms_of_use_country_description: {
              raw: any;
            };
          };
        }[];
      };
    };
  };
}

const TermsOfUsePage: React.FC<TermsOfUsePageProps> = ({ data }) => {
  const countryTerms = data.prismicTermsOfUsePage.data.body[0].primary;

  return (
    <div className="p-8">
      <h1 className="text-2xl font-bold mb-4">Terms of Use</h1>

      <div className="mb-8">
        <h2 className="text-lg font-semibold mb-2">Country Title</h2>
        <TermsRichText text={countryTerms.terms_of_use_country_title} />
      </div>

      <div className="mb-8">
        <h2 className="text-lg font-semibold mb-2">Country Description</h2>
        <TermsRichText text={countryTerms.terms_of_use_country_description} />
      </div>
    </div>
  );
};

export const query = graphql`
  query ($lang: String) {
    prismicTermsOfUsePage(lang: { eq: $lang }) {
      data {
        body {
          ... on PrismicTermsOfUsePageDataBodyTermsOfUse {
            items {
              term_description {
                raw
                text
              }
              term_navigation_label {
                raw
                text
              }
              term_title {
                raw
                text
              }
            }
            primary {
              content_label {
                raw
                text
              }
              country_code
              country_nav_label {
                raw
                text
              }
              last_update_label {
                raw
                text
              }
              terms_of_use_country_description {
                raw
                text
              }
              terms_of_use_country_title {
                raw
                text
              }
            }
          }
        }
        page_author {
          text
        }
        body1 {
          ... on PrismicTermsOfUsePageDataBody1DataTable {
            items {
              cell_column_1 {
                raw
                text
              }
              cell_column_2 {
                raw
                text
              }
              cell_column_3 {
                raw
                text
              }
              cell_column_4 {
                raw
                text
              }
            }
            primary {
              header_column_1 {
                raw
                text
              }
              header_column_2 {
                raw
                text
              }
              header_column_3 {
                raw
                text
              }
              header_column_4 {
                raw
                text
              }
              id
            }
          }
        }
        body2 {
          ... on PrismicTermsOfUsePageDataBody2NoteBoard {
            primary {
              note_id
              note_content {
                raw
                text
              }
            }
          }
        }
        page_description {
          text
        }
        page_keywords {
          text
        }
        page_og_description {
          text
        }
        page_og_title {
          text
        }
        page_og_type {
          text
        }
        page_title {
          text
        }
        page_twitter_card {
          text
        }
        page_twitter_description {
          text
        }
        page_twitter_title {
          text
        }
        title {
          text
          raw
        }
      }
    }
  }
`;

export default TermsOfUsePage;
