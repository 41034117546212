import React from 'react';
import { RichText as PrismicRichText } from 'prismic-reactjs';
import { linkResolver } from '../../../../../../src/services/linkResolver';
import TermsHtmlSerializer from './termHtmlSerializer';

interface TermsRichTextProps {
  text: {
    raw: any;
  };
}

const TermsRichText: React.FC<TermsRichTextProps> = ({ text }) => (
  <PrismicRichText
    render={text?.raw}
    htmlSerializer={TermsHtmlSerializer}
    linkResolver={linkResolver}
  />
);
export default TermsRichText;
