import styled from 'styled-components';
import { Link } from '@components/atoms';

export const StyledParagraph = styled.p`
  font-family: 'Inter Light', sans-serif;
  font-size: 16px;
  line-height: normal;
  padding-bottom: 1rem;
  @media (min-width: 1024px) {
    padding-bottom: 1.5rem;
  }
`;

export const StyledHeading2 = styled.h2`
  font-family: 'Inter SemiBold', sans-serif;
  text-transform: uppercase;
  font-size: 24px;
  line-height: 1;
`;

export const StyledHeading3 = styled.h3`
  font-family: 'Inter SemiBold', sans-serif;
  text-transform: uppercase;
  font-size: 20px;
  line-height: 1;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  @media (min-width: 1024px) {
    font-size: 28px;
  }
`;

export const StyledLink = styled(Link)`
  color: green;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const StyledList = styled.ul`
  list-style-type: disc;
  padding-left: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;

export const StyledOrderedList = styled.ol`
  list-style-type: decimal;
  padding-left: 1.5rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;
